// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

//#####################################################################################################
//# BOOTSTRAP
//#####################################################################################################

require("./bootstrap")

//#####################################################################################################
//# JQUERY
//#####################################################################################################

require("jquery")

//#####################################################################################################
//# SB-ADMIN
//#####################################################################################################

require("startbootstrap-sb-admin/src/js/scripts")

//#####################################################################################################
//# FONT AWESOME
//#####################################################################################################

import "@fortawesome/fontawesome-free/css/all"
import "@fortawesome/fontawesome-pro/css/all"
