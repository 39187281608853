import * as bootstrap from 'bootstrap'

document.addEventListener("DOMContentLoaded", function(event) {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});

(function($) {
  "use strict";
  $(document).ready(function() {init();});

  const init = function() {
    if ($('#modal .modal-body').length) {
      const reset_modal_tooltips = () => {
        if ($('[data-bs-toggle="modal-tooltip"]').length) {
          var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="modal-tooltip"]'))
          var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
          })
        }
      }

      const observer = new MutationObserver(records => {
        reset_modal_tooltips();
      });

      observer.observe($('#modal .modal-body')[0], { childList: true });
    }
  }

})(jQuery);
